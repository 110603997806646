import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo";
import AssociatesNavList from "../components/associates/nav_items";
import College_hero from "../components/associates/colleges/college_hero";
import Business_laboratory from "../components/associates/colleges/business_labrotary";
import Out_comeBl from "../components/associates/colleges/out_comeBL";
import Infrastructure from "../components/associates/colleges/infrastrature";
import ContactUS from "../components/contact-us";
const Associates = () => (
  <Layout navName="Associates">
    <Seo title="Associates" />
    {/* <AssociatesNavList navName="Mentor" /> */}

   <College_hero/>
    <Business_laboratory />
    <Out_comeBl />
    <Infrastructure />
    <ContactUS/>
  </Layout>
)

export default Associates
